<template>
    <div class="basePan mobilePan">
        <div class="bgTopPan"></div>
        <div class="baseContentPan">
            <div style="height: 0.25rem;" ></div>
            <div class="formImg" >
                <img src="../../../assets/img/logo.png" style="width: 1.43rem;height: 0.33rem;" />
            </div>
            <div style="height: 0.28rem;" ></div>
            <div class="formTitle" >
                用户登录 <a v-if="$store.state.source && $store.state.source == 'website'" href="https://www.shoppaas.com/"
                        class="back-guanw">返回官网</a>
            </div>
            <div style="height: 0.09rem;" ></div>
            <div class="formDesc" >
                独立建站平台，完整的店铺管理
            </div>
            <div style="height: 0.28rem;" ></div>
            <div class="formPan" >
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item prop="email">
                        <el-input class="formInput" v-model="ruleForm.email" placeholder="请输入您的邮箱">
                            <template slot="prefix">
                                <img src="../../../assets/img/eml2.png" />
                            </template>
                        </el-input>
                    </el-form-item>
                    <div style="height: 0.21rem;" ></div>
                    <el-form-item prop="password">
                        <el-input class="formInput" v-model="ruleForm.password" placeholder="请输入您的密码"  :type="passwordShow ? 'password' : 'text'">
                            <template slot="prefix">
                                <img src="../../../assets/img/mima2.png" />
                            </template>
                             <template slot="suffix" v-if="$store.state.source && $store.state.source == 'website'">
                              <i @click="passwordShow = !passwordShow" class="el-icon-view input-view-zdy"></i>
                          </template>
                        </el-input>
                    </el-form-item>
                    <div style="height: 0.24rem;" ></div>
                    <el-button type="primary" class="form-submit-bth" @click="loging" :loading="loginLoading">立即登录</el-button>
                </el-form>
            </div>
            <div style="height: 0.13rem;" ></div>
            <div class="formLink" >
                还没有shoppaas合作伙伴账号？<span class="toReg" @click="()=>{$router.push('/register')}" >立即注册</span>&nbsp;&nbsp;
                <span class="toPwd" @click="()=>{$router.push('/resetpwd')}" >忘记密码?</span>
            </div>
            <div  style="height: 0.30rem;" ></div>
        </div>
        <div class="bgBottomPan"></div>
    </div>
</template>
<script>

import user from '@/api/user'
export default{
    name:'mobile',
    data(){
        return {
            loginLoading: false,
             passwordShow: true,
            ruleForm:{
                email:"",
                password:""
            },
            rules:{
                email:[
                    { validator: this.validatorEmail, trigger: 'change' }
                ],
                password:[
                    { validator: this.validatorPassword, trigger: 'change' },
                    { validator: this.validatorChineseText, trigger: 'change' },
                ]
            }
        }
    },
    created(){
        const setFontSize= () => {
            const htmlDom = document.getElementsByTagName('html')[0];
            let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
            if (htmlWidth >= 750) {
                htmlWidth = 750;
            }
            if (htmlWidth <= 320) {
                htmlWidth = 320;
            }
            htmlDom.style.fontSize = `${htmlWidth / 3.75}px`
        };
        window.onresize = setFontSize;
        setFontSize();
          if (this.$route.query.source) {
            this.$store.commit('sourcechaneg', this.$route.query.source);
            sessionStorage.setItem('source', this.$route.query.source)
        }
    },
    methods:{
        loging(){
        this.$refs.ruleForm.validate((valid) => {
            if(valid){
            this.loginLoading = true
            const data = {
                type:'1',
                ...this.ruleForm
            }
            user.login(data).then(res => {
                console.log(res)
                if(res.result && res.result.length >0 ){
                    if(res.result.length>1){
                        window.storeList=res.result;
                        this.$router.push('/multistore');
                    }else{
                        localStorage.setItem('authorization', "")
                        this.$message({
                            message: '登录成功',
                            type: 'success',
                            customClass:'myPhoneMsg',
                        });
                        setTimeout(()=>{
                                window.location.href=res.result[0].login_url;
                            },500)
                        }
                    }else {
                        this.$message({
                            message: '获取店铺列表失败, 请联系管理员',
                            type: 'error',
                            customClass:'myPhoneMsg',
                        });
                    }
                // setTimeout(()=>{
                //   this.$router.push('/home')
                // },500)
            }).finally(()=>{
                this.loginLoading = false
            })
            }else{
            return false
            }
        })
        },
        /**
         * @desc 验证邮箱是否添写并符合要求
         */
        validatorEmail(rule, value, callback){
        if(!value){
            return callback(new Error('请输入邮箱'))
        }

        if(!(/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value))){
            return callback(new Error('请输入正确的邮箱'))
        }
        callback()
        },
        /**
         * @desc 验证密码是否添加并符合要求
         */
        validatorPassword(rule, value, callback){
        if(!value) return callback(new Error('请输入密码'))
        if(!(/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]*$/.test(value))){
            return callback(new Error('密码必须包含小写字母、大写字母、数字和特殊符号中的三个'))
        }
        if(value.length < 8 || value.length > 30){
            return callback(new Error('密码长度必须为8-30位'))
        }
        callback()
        },
        /**
         * @desc 验证是否包含中文
         */
        validatorChineseText(rule, value, callback){
        let re = /.*[\u4e00-\u9fa5]+.*$/;
        if(re.test(value)){
            callback(new Error('请输入英文字母或数字'));
        }
        callback()
        },
    }
}
</script>
<style lang='scss' scoped>
::v-deep .el-input__suffix{
     right: .14rem;
    top: .09rem;
}
::v-deep .el-input--suffix .el-input__inner{
  padding-right: .55rem;
}
.input-view-zdy{
    font-size: .18rem;
    cursor: pointer;
  }
.basePan{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    line-height: 0px;
    .bgTopPan{
        background-image: url(../../../assets/img/mobile_bg_top.png);
        //background-size: cover;
        background-size: 100% 3.72rem;
        width: 100%;
        height: 3.72rem;
        background-repeat: no-repeat;
    }
    .baseContentPan{
        min-height: 3.76rem;
        height: auto;
        width: 3.1rem;
        background: #FFFFFF;
        box-shadow: 0px 10px 15px 5px rgba(174,174,174,0.2);
        border-radius: 0.1rem;
        position: absolute;
        z-index: 9999;
        top: 2.32rem;
        left: 50%;
        margin-left: -1.55rem;
        padding-left: 0.18rem;
        padding-right: 0.18rem;
        .formPan{
            width: 100%;
        }
        .formImg{
            margin-left: 0.02rem;
            padding: 0rem;
            line-height: 0rem;
            img{
                display: block;
            }
        }
        .formTitle{
            margin-left: 0.09rem;
        }
        .formDesc{
            margin-left: 0.09rem;
        }
        .formLink{
            width: 2.3rem;
            height: 0.30rem;
            font-size: 0.12rem;
            font-weight: 400;
            color: #C6C7D1;
            margin: 0 auto;
            line-height: 0.16rem;
            .toReg{
                cursor: pointer;
                color: #4E73DF;
            }
            .toPwd{
                color: #4E73DF;
                cursor: pointer;
            }
        }
    }
    // .contentBoth{
    //     position: absolute;
    //     height: 300px;
    //     width: 100%;
    // }
    .bgBottomPan{
        background-image: url(../../../assets/img/mobile_bg_bottom.png);
        //background-size: cover;
        background-size: 100% 100%;
        width: 100%;
        height: 1.18rem;
        background-repeat: no-repeat;
        position:fixed;
        bottom: 0px;
    }
}
</style>
