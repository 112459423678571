<template>
  <div class="">
    
  </div>
</template>

<script>
export default {
  data(){
    return {
      
    }
  },
  beforeRouteEnter(to,from,next){
    // todo 首页应放官网的内容,暂时屏蔽,直接默认进登陆
    next({path:'/login'})
    // if(!localStorage.getItem('userInfo')){
    //   next({path:'login'})
    // }else{
    //   next()
    // }
  },
  created () {
    if (this.$route.query.code !== undefined) {
      sessionStorage.setItem('Code', this.$route.query.code)
    }
  },
  methods:{}
}
</script>

<style lang='scss' scoped>

</style>
