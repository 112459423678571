import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    source: sessionStorage.getItem('source')||'',
  },
  getters: {},
  mutations: {
    sourcechaneg(state, value) {
      state.source = value
    },
  },
  actions: {},
  modules: {},
})
