import { request } from '@/axios/request';

const prefix = "/user"

let user = {
    verificationCode : (data) => request(prefix +'/ems/send','POST',data),
    registerAccount : (data) => request(prefix +'/register','POST',data),
    registerAccountStatus : (id) => request(prefix +'/register_status?id='+id,'GET'),
    resetPwdAccount : (data) => request(prefix +'/resetpwd','POST',data),
    login : (data) => request(prefix +'/login','POST',data),
    loginAddr: (data) => request(prefix +'/shop_login','POST',data),
}
export default user
