<template>
    <div class="basePan pcPan" :style="{ backgroundSize: bgWidth + ' ' + bgHeight + '' }">
        <div :style="{ height: contentTop + 'px' }"></div>
        <div class="baseContentPan">
            <div class="formImg">
                <img src="../../../assets/img/logo.png" style="width: 286px;height: 68px;" />
            </div>
            <div v-if="winHeight >= 768" style="height: 46px;"></div>
            <div class="formTitle">
                用户登录 <a v-if="$store.state.source && $store.state.source == 'website'" href="https://www.shoppaas.com/"
                    class="back-guanw">返回官网</a>
            </div>
            <div style="height: 18px;"></div>
            <div class="formDesc">
                独立建站平台，完整的店铺管理
            </div>
            <div style="height: 52px;"></div>
            <div class="formPan">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item prop="email">
                        <el-input class="formInput" v-model="ruleForm.email" placeholder="请输入您的邮箱"
                            @focus="() => { focusIndex = 1; }" @blur="() => { focusIndex = -1; }">
                            <template slot="prefix">
                                <img v-if="focusIndex === 1" src="../../../assets/img/eml2.png"
                                    style="width: 20px;height: 20px;" />
                                <img v-else src="../../../assets/img/eml.png" style="width: 20px;height: 20px;" />
                                <!-- <div class="iconPan" >
                                    
                                </div> -->
                            </template>
                        </el-input>
                    </el-form-item>
                    <div style="height: 48px;"></div>
                    <el-form-item prop="password">
                        <el-input class="formInput" v-model="ruleForm.password" placeholder="请输入您的密码"
                            :type="passwordShow ? 'password' : 'text'" @focus="() => { focusIndex = 2; }"
                            @blur="() => { focusIndex = -1; }">
                            <template slot="prefix">
                                <img v-if="focusIndex === 2" src="../../../assets/img/mima2.png"
                                    style="width: 20px;height: 20px;" />
                                <img v-else src="../../../assets/img/mima.png" style="width: 20px;height: 20px;" />
                            </template>
                            <template slot="suffix" v-if="$store.state.source && $store.state.source == 'website'">
                                <i @click="passwordShow = !passwordShow" class="el-icon-view input-view-zdy"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <div style="height: 48px;"></div>
                    <el-button type="primary" class="form-submit-bth" @click="loging"
                        :loading="loginLoading">立即登录</el-button>
                </el-form>
            </div>
            <div style="height: 17px;"></div>
            <div class="formLink">
                还没有shoppaas合作伙伴账号？<span class="toReg" @click="() => { $router.push('/register') }">立即注册</span>&nbsp;&nbsp;
                <span class="toPwd" @click="() => { $router.push('/resetpwd') }">忘记密码?</span>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/store';
import user from '@/api/user'
export default {
    name: 'login',
    store,
    data () {
        return {
            loginLoading: false,
            passwordShow: true,
            ruleForm: {
                email: "",
                password: ""
            },
            rules: {
                email: [
                    { validator: this.validatorEmail, trigger: 'change' },
                ],
                password: [
                    { validator: this.validatorPassword, trigger: 'change' },
                    { validator: this.validatorChineseText, trigger: 'change' },
                ]
            },
            focusIndex: -1,
            contentTop: 148,
            winHeight: 0,
            bgWidth: '1920px',
            bgHeight: '1080px',
        }
    },
    mounted () {
        //兼容处理
        this.winHeight = window.screen.height;
        if (this.winHeight < 960) {
            this.contentTop = 38;
        }
        if (this.winHeight < 800) {
            this.contentTop = 0;
        }
        if (this.winHeight > 1080) {
            this.bgHeight = "100%";
        }
        if (window.screen.width > 1920) {
            this.bgWidth = "100%";
        }
    },
    created () {
          if (this.$route.query.source) {
            this.$store.commit('sourcechaneg', this.$route.query.source);
            sessionStorage.setItem('source', this.$route.query.source)
        }
    },
    methods: {
        loging () {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loginLoading = true
                    const data = {
                        type: '1',
                        ...this.ruleForm
                    }
                    user.login(data).then(res => {
                        console.log(res)
                        if (res.result && res.result.length > 0) {
                            if (res.result.length > 1) {
                                window.storeList = res.result;
                                this.$router.push('/multistore');
                            } else {
                                localStorage.setItem('authorization', "")
                                this.$message.success('登录成功')
                                setTimeout(() => {
                                    window.location.href = res.result[0].login_url;
                                }, 500)
                            }
                        } else {
                            this.$message.error('获取店铺列表失败, 请联系管理员')
                        }
                        // setTimeout(()=>{
                        //   this.$router.push('/home')
                        // },500)
                    }).finally(() => {
                        this.loginLoading = false
                    })
                } else {
                    return false
                }
            })
        },
        /**
         * @desc 验证邮箱是否添写并符合要求
         */
        validatorEmail (rule, value, callback) {
            if (!value) {
                return callback(new Error('请输入正确邮箱'))
            }

            if (!(/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value))) {
                return callback(new Error('请输入正确的邮箱'))
            }
            callback()
        },
        /**
         * @desc 验证密码是否添加并符合要求
         */
        validatorPassword (rule, value, callback) {
            if (!value) return callback(new Error('请输入密码'))
            if (!(/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]*$/.test(value))) {
                return callback(new Error('密码必须包含小写字母、大写字母、数字和特殊符号中的三个'))
            }
            if (value.length < 8 || value.length > 30) {
                return callback(new Error('密码长度必须为8-30位'))
            }
            callback()
        },
        /**
         * @desc 验证是否包含中文
         */
        validatorChineseText (rule, value, callback) {
            let re = /.*[\u4e00-\u9fa5]+.*$/;
            if (re.test(value)) {
                callback(new Error('请输入英文字母或数字'));
            }
            callback()
        },
    }
}
</script>

<style lang='scss' scoped>
::v-deep .el-input__suffix {
    right: 56px;
    top: 21px;
}

::v-deep .el-input--suffix .el-input__inner {
    padding-right: 55px;
}

.input-view-zdy {
    font-size: 22px;
    cursor: pointer;
}

::v-deep .demo-ruleForm .el-form-item__content .el-input__inner {
    height: 64px;
    border-radius: 32px;
}

.basePan {
    background-image: url(../../../assets/img/reg_bg.png);
    //background-size:1920px 1080px;
    //background-size:1920px 100%;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-repeat: no-repeat;

    .baseContentPan {
        margin-left: 304px;

        .formImg {
            margin-left: 15px;
        }

        .formTitle {
            font-size: 32px;
        }

        .demo-ruleForm {
            .form-submit-bth {
                height: 64px;
                border-radius: 32px;
            }
        }

        .formLink {
            font-size: 20px;
            color: #C6C7D1;
            width: 90%;
            margin: 0 auto;
            line-height: 30px;

            .toReg {
                cursor: pointer;
                color: #4E73DF;
            }

            .toPwd {
                color: #4E73DF;
                cursor: pointer;
            }
        }
    }
}</style>
